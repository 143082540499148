const events = [
  // {
  //   id: 1,
  //   title: "Концерт в Южноуральске",
  //   date: "13 февраля 2025",
  //   time: "19:00",
  //   place: "Южноуральск, Россия",
  //   arena: "Городской дом культуры",
  //   link: "https://quicktickets.ru/yujnouralsk-dom-kultury/e592"
  // },
  // {
  //   id: 2,
  //   title: "Концерт в МИАСС",
  //   date: "14 февраля 2025",
  //   time: "19:00",
  //   place: "Миасс, Россия",
  //   arena: "ДКиТ Прометей",
  //   link: "https://miass.kassy.ru/events/koncerty-i-shou/2-44239/"
  // },
  // {
  //   id: 3,
  //   title: "Концерт в Златоуст",
  //   date: "15 февраля 2025",
  //   time: "18:00",
  //   place: "Златоуст, Россия",
  //   arena: "Дворец культуры Железнодорожнки",
  //   link: "https://forma1.ru/bcalendar?site=zlatdkzd.ru"
  // },
  // {
  //   id: 4,
  //   title: "Концерт в Троицке",
  //   date: "16 марта 2025",
  //   time: "17:00",
  //   place: "Троицк, Россия",
  //   arena: "Дом Культуры",
  //   link: "https://iframeab-pre9696.intickets.ru/seance/50068781/"
  // },
  // {
  //   id: 5,
  //   title: "Выступление в Москве",
  //   date: "6 марта 2025",
  //   time: "19:00",
  //   place: "Москва, Россия",
  //   arena: "Ресторан Кариночка",
  //   link: "https://iframeab-pre9696.intickets.ru/seance/50889355/"
  // },
  // {
  //   id: 6,
  //   title: "Выступление в Воскресенск",
  //   date: "9 марта 2025",
  //   time: "19:00",
  //   place: "Воскресенск, Московская обл. Россия",
  //   arena: "ДК ХИМИК",
  //   link: "https://iframeab-pre5398.intickets.ru/seance/51470538/?scr_v=i_1.19&int_enter_referrer=http%253A%252F%252Fdkdok.ru%252F&int_enter_url=http%253A%252F%252Fdkdok.ru%252Findex.php%252Fafishi%252Fitem%252F856-gruppa-doktor-shlyager&int_addit=%257B%2522window%2522%253A%257B%2522width%2522%253A390%252C%2522height%2522%253A844%257D%257D&_ym_uid=1734094740981980853#abiframe"
  // },
  // {
  //   id: 7,
  //   title: "Выступление в Электросталь",
  //   date: "13 марта 2025",
  //   time: "19:00",
  //   place: "Электросталь, Московская обл. Россия",
  //   arena: "ДК Карла Маркса",
  //   link: "https://iframeab-pre9696.intickets.ru/seance/51655323"
  // // },
  // {
  //   id: 8,
  //   title: "Выступление в Лянтор",
  //   date: "1 апреля 2025",
  //   time: "19:00",
  //   place: "Лянтор, Россия",
  //   arena: "ДК НЕФТЯНИК",
  //   link: "https://iframeab-pre9696.intickets.ru/seance/51759610"
  // },
  // {
  //   id: 9,
  //   title: "Выступление в Мегион",
  //   date: "2 апреля 2025",
  //   time: "19:00",
  //   place: "Мегион, Россия",
  //   arena: "Дворец искуств",
  //   link: "https://widget.p24.app/cfca4494-9a5c-4a7a-b162-3c8453130be9/theater/activity/475e68f5-74f9-4fd8-9dcc-80c44d622fd3/event/97c17874-b4e2-40c1-9b32-86ef858a0163"
  // },
  // {
  //   id: 10,
  //   title: "Выступление в Нижневартовске",
  //   date: "4 апреля 2025",
  //   time: "19:00",
  //   place: "Нижневартовск, Россия",
  //   arena: "ДК ОКТЯБРЬ",
  //   link: "https://iframeab-pre5123.intickets.ru/seance/51666677"
  // },

  // {
  //   id: 12,
  //   title: "Выступление в Федоровский",
  //   date: "5 апреля 2025",
  //   time: "18:00",
  //   place: "Федоровский, Россия",
  //   arena: "КДЦ ПРЕМЬЕР",
  //   link: "https://iframeab-pre9696.intickets.ru/seance/52537913/"
  // },
  // {
  //   id: 13,
  //   title: "Выступление в Нефтиюганск",
  //   date: "6 апреля 2025",
  //   time: "18:00",
  //   place: "Нефтиюганск, Россия",
  //   arena: "КЦ ЮНОСТЬ",
  //   link: "https://quicktickets.ru/nefteyugansk-kdk/e158"
  // },
  {
    id: 14,
    title: "Выступление в Покачи",
    date: "7 апреля 2025",
    time: "19:00",
    place: "Покачи, Россия",
    arena: "КЦ Октябрь",
    link: "https://iframeab-pre9696.intickets.ru/seance/55711884"
  }, 
  {
    id: 15,
    title: "Выступление в Тобольск ",
    date: "8 апреля 2025",
    time: "19:00",
    place: "Тобольск, Россия",
    arena: "КЦ Октябрь",
    link: "https://tmn.kassir.ru/koncert/gruppa-doktor-shlyager-1#2753209"
  },
  {
    id: 16,
    title: "Выступление в Искра",
    date: "11 апреля 2025",
    time: "19:00",
    place: "Искра Россия",
    arena: "Истринский Дом Культуры",
    link: ""
  },







];

export default events;
